<template>
  <div ref="twitter" class="max-w-lg mx-auto" />
</template>

<script setup lang="ts">
import type { WP_Embed_Response } from '~/types/interfaces'

const props = defineProps<{ embed: WP_Embed_Response }>()

const tweetId = props.embed.url.split('/').pop()

const twitter = ref<HTMLElement | null>(null)

onMounted(() => {
  if (twitter.value) {
    window.twttr.widgets.createTweet(tweetId, twitter.value, { align: 'center' })
  }
})
</script>