<template>
  <div v-if="showPlayer" v-html="embed.html" class="aspect-w-16 aspect-h-9 rounded-md overflow-hidden shadow max-w-lg mx-auto" />
  <UCard v-else :ui="cardUi" @click.stop="showPlayer = true">
    <figure class="relative group/embed">
      <div>
        <div class="aspect-w-16 aspect-h-9">
          <img v-if="embed.thumbnail_url" :src="useFeaturedImageUrl(embed.thumbnail_url, 512)" class="object-cover">
        </div>
      </div>
      <figcaption class="flex gap-4 p-3 bg-white/90 dark:bg-gray-700 backdrop-blur items-center absolute left-0 bottom-0 right-0">
        <UButton @click.stop="showPlayer = true" size="xl" color="gray" class="rounded-full" icon="i-heroicons-play-solid" />
        <hgroup>
          <h2 class="leading-tight line-clamp-1 group-hover/embed:underline">{{ embed.title }}</h2>
          <p class="mb-0 text-gray-600 dark:text-gray-500 text-xs">{{ embed.author_name }} &bull; {{ embed.provider_name }}</p>
        </hgroup>
      </figcaption>
    </figure>
  </UCard>
</template>

<script setup lang="ts">
import type { WP_Embed_Response } from '~/types/interfaces';

const props = defineProps<{
  embed: WP_Embed_Response,
}>()

const showPlayer = ref(false)

const cardUi = {
  base: 'overflow-hidden cursor-pointer max-w-lg mx-auto',
  body: {
    padding: 'p-0 px-0 py-0 sm:p-0',
  }
}
</script>