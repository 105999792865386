<template>
  <NuxtPwaAssets />
  <NuxtLoadingIndicator color="#0F4C81" />
  <NuxtPage keepalive />
  <UNotifications />
</template>

<script setup lang="ts">
useSeoMeta({
  title: 'Western Montana News',
  description: 'Western Montana community-powered news and information. Local news, sports, business, politics, and more. For Western Montana, by Western Montana.',
  ogType: 'website',
  ogImage: '/apple-splash-landscape-2224x1668.png',
  ogUrl: 'https://www.westernmt.news/',
  twitterCard: 'summary_large_image',
  twitterImage: '/apple-splash-landscape-2224x1668.png',
  twitterSite: '@westernmtnews',
  twitterCreator: '@westernmtnews',
})

useHead({
  htmlAttrs: {
    lang: 'en'
  },
  meta: [
    {
      name: 'mobile-web-app-capable',
      content: 'yes'
    },
    {
      name: 'apple-mobile-web-app-capable',
      content: 'yes'
    },
    {
      name: 'theme-color',
      content: '#ffffff',
      media: '(prefers-color-scheme: light)'
    },
    {
      name: 'theme-color',
      content: '#111827',
      media: '(prefers-color-scheme: dark)'
    }
  ],
  link: [
    // Landscape
    { rel: 'apple-touch-startup-image', href: '/apple-splash-landscape-1136x640.png', media: '(device-width: 1136px) and (device-height: 640px) and (orientation: landscape) and (-webkit-device-pixel-ratio: 2)' },
    { rel: 'apple-touch-startup-image', href: '/apple-splash-landscape-1334x750.png', media: '(device-width: 1334px) and (device-height: 750px) and (orientation: landscape) and (-webkit-device-pixel-ratio: 2)' },
    { rel: 'apple-touch-startup-image', href: '/apple-splash-landscape-1792x828.png', media: '(device-width: 1792px) and (device-height: 828px) and (orientation: landscape) and (-webkit-device-pixel-ratio: 3)' },
    { rel: 'apple-touch-startup-image', href: '/apple-splash-landscape-2048x1536.png', media: '(device-width: 2048px) and (device-height: 1536px) and (orientation: landscape) and (-webkit-device-pixel-ratio: 2)' },
    { rel: 'apple-touch-startup-image', href: '/apple-splash-landscape-2160x1620.png', media: '(device-width: 2160px) and (device-height: 1620px) and (orientation: landscape) and (-webkit-device-pixel-ratio: 3)' },
    { rel: 'apple-touch-startup-image', href: '/apple-splash-landscape-2208x1242.png', media: '(device-width: 2208px) and (device-height: 1242px) and (orientation: landscape) and (-webkit-device-pixel-ratio: 3)' },
    { rel: 'apple-touch-startup-image', href: '/apple-splash-landscape-2224x1668.png', media: '(device-width: 2224px) and (device-height: 1668px) and (orientation: landscape) and (-webkit-device-pixel-ratio: 2)' },
    { rel: 'apple-touch-startup-image', href: '/apple-splash-landscape-2388x1668.png', media: '(device-width: 2388px) and (device-height: 1668px) and (orientation: landscape) and (-webkit-device-pixel-ratio: 2)' },
    { rel: 'apple-touch-startup-image', href: '/apple-splash-landscape-2436x1125.png', media: '(device-width: 2436px) and (device-height: 1125px) and (orientation: landscape) and (-webkit-device-pixel-ratio: 3)' },
    { rel: 'apple-touch-startup-image', href: '/apple-splash-landscape-2532x1170.png', media: '(device-width: 2532px) and (device-height: 1170px) and (orientation: landscape) and (-webkit-device-pixel-ratio: 3)' },
    { rel: 'apple-touch-startup-image', href: '/apple-splash-landscape-2556x1179.png', media: '(device-width: 2556px) and (device-height: 1179px) and (orientation: landscape) and (-webkit-device-pixel-ratio: 3)' },
    { rel: 'apple-touch-startup-image', href: '/apple-splash-landscape-2688x1242.png', media: '(device-width: 2688px) and (device-height: 1242px) and (orientation: landscape) and (-webkit-device-pixel-ratio: 3)' },
    { rel: 'apple-touch-startup-image', href: '/apple-splash-landscape-2732x2048.png', media: '(device-width: 2732px) and (device-height: 2048px) and (orientation: landscape) and (-webkit-device-pixel-ratio: 2)' },
    { rel: 'apple-touch-startup-image', href: '/apple-splash-landscape-2778x1284.png', media: '(device-width: 2778px) and (device-height: 1284px) and (orientation: landscape) and (-webkit-device-pixel-ratio: 3)' },
    { rel: 'apple-touch-startup-image', href: '/apple-splash-landscape-2796x1290.png', media: '(device-width: 2796px) and (device-height: 1290px) and (orientation: landscape) and (-webkit-device-pixel-ratio: 3)' },
    
    // Portrait
    { rel: 'apple-touch-startup-image', href: '/apple-splash-portrait-640x1136.png', media: '(device-width: 640px) and (device-height: 1136px) and (orientation: portrait) and (-webkit-device-pixel-ratio: 2)' },
    { rel: 'apple-touch-startup-image', href: '/apple-splash-portrait-750x1334.png', media: '(device-width: 750px) and (device-height: 1334px) and (orientation: portrait) and (-webkit-device-pixel-ratio: 2)' },
    { rel: 'apple-touch-startup-image', href: '/apple-splash-portrait-828x1792.png', media: '(device-width: 828px) and (device-height: 1792px) and (orientation: portrait) and (-webkit-device-pixel-ratio: 2)' },
    { rel: 'apple-touch-startup-image', href: '/apple-splash-portrait-1125x2436.png', media: '(device-width: 1125px) and (device-height: 2436px) and (orientation: portrait) and (-webkit-device-pixel-ratio: 3)' },
    { rel: 'apple-touch-startup-image', href: '/apple-splash-portrait-1170x2532.png', media: '(device-width: 1170px) and (device-height: 2532px) and (orientation: portrait) and (-webkit-device-pixel-ratio: 3)' },
    { rel: 'apple-touch-startup-image', href: '/apple-splash-portrait-1179x2556.png', media: '(device-width: 1179px) and (device-height: 2556px) and (orientation: portrait) and (-webkit-device-pixel-ratio: 3)' },
    { rel: 'apple-touch-startup-image', href: '/apple-splash-portrait-1242x2208.png', media: '(device-width: 1242px) and (device-height: 2208px) and (orientation: portrait) and (-webkit-device-pixel-ratio: 3)' },
    { rel: 'apple-touch-startup-image', href: '/apple-splash-portrait-1242x2688.png', media: '(device-width: 1242px) and (device-height: 2688px) and (orientation: portrait) and (-webkit-device-pixel-ratio: 3)' },
    { rel: 'apple-touch-startup-image', href: '/apple-splash-portrait-1284x2778.png', media: '(device-width: 1284px) and (device-height: 2778px) and (orientation: portrait) and (-webkit-device-pixel-ratio: 3)' },
    { rel: 'apple-touch-startup-image', href: '/apple-splash-portrait-1290x2796.png', media: '(device-width: 1290px) and (device-height: 2796px) and (orientation: portrait) and (-webkit-device-pixel-ratio: 3)' },
    { rel: 'apple-touch-startup-image', href: '/apple-splash-portrait-1536x2048.png', media: '(device-width: 1536px) and (device-height: 2048px) and (orientation: portrait) and (-webkit-device-pixel-ratio: 2)' },
    { rel: 'apple-touch-startup-image', href: '/apple-splash-portrait-1620x2160.png', media: '(device-width: 1620px) and (device-height: 2160px) and (orientation: portrait) and (-webkit-device-pixel-ratio: 2)' },
    { rel: 'apple-touch-startup-image', href: '/apple-splash-portrait-1668x2224.png', media: '(device-width: 1668px) and (device-height: 2224px) and (orientation: portrait) and (-webkit-device-pixel-ratio: 2)' },
    { rel: 'apple-touch-startup-image', href: '/apple-splash-portrait-1668x2388.png', media: '(device-width: 1668px) and (device-height: 2388px) and (orientation: portrait) and (-webkit-device-pixel-ratio: 2)' },
    { rel: 'apple-touch-startup-image', href: '/apple-splash-portrait-2048x2732.png', media: '(device-width: 2048px) and (device-height: 2732px) and (orientation: portrait) and (-webkit-device-pixel-ratio: 2)' }
  ]
})
</script>