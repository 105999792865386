import { useOneSignal } from '@onesignal/onesignal-vue3'
export default defineNuxtPlugin((nuxtApp) => {

  if (process.env.NODE_ENV === 'development') return

  // Brave does not support push notifications from OneSignal
  if (navigator.hasOwnProperty('brave')) return

  const { public: { oneSignalAppId: appId } } = nuxtApp.$config

  const { data: user } = useAuth()

  const OneSignal = useOneSignal()

  OneSignal.init({
    appId,
    allowLocalhostAsSecureOrigin: true,
    serviceWorkerPath: 'push/onesignal/OneSignalSDKWorker.js',
    serviceWorkerParam: { scope: '/push/onesignal/' },
    notifyButton: {
      enable: false
    }
  })

  // Watch user change and login to OneSignal when user is logged in
  watch(user, async (newUser) => {
    if (newUser) {
      const { slug } = newUser
      await OneSignal.login(slug)
    }
  })

  return {
    provide: {
      OneSignal
    }
  }
})