import validate from "/tmp/build_53a3778f/node_modules/nuxt/dist/pages/runtime/validate.js";
import user_45path_45redirect_45global from "/tmp/build_53a3778f/middleware/userPathRedirect.global.ts";
import manifest_45route_45rule from "/tmp/build_53a3778f/node_modules/nuxt/dist/app/middleware/manifest-route-rule.js";
export const globalMiddleware = [
  validate,
  user_45path_45redirect_45global,
  manifest_45route_45rule
]
export const namedMiddleware = {
  auth: () => import("/tmp/build_53a3778f/node_modules/@sidebase/nuxt-auth/dist/runtime/middleware/auth.mjs")
}