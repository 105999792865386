export default function (sourceUrl: string, width?: number, height?: number, quality?: number) {
  const { public: { nodeEnv } } = useRuntimeConfig()
  if (nodeEnv !== 'production') return sourceUrl
  const zone = 'https://www.westernmt.news'
  const prefix = '/cdn-cgi/image/'
  const options = []
  if (width) {
    options.push(`width=${width}`)
  }
  if (height) {
    options.push(`height=${height}`)
  }
  if (quality) {
    options.push(`quality=${quality}`)
  }
  options.push('format=auto')
  return `${zone}${prefix}${options.join(',')}/${sourceUrl}`
}