import { createApp } from 'vue'
import type { DirectiveBinding } from 'vue'
import OembedElement from '~/components/oembed/OembedElement.vue';
import { useRuntimeConfig } from '#app';

function convertLinksToOembed(htmlString: string): string {
  // Combined regular expression to match naked URLs and anchor tags
  const combinedRegex = /(?<!href="|">)(https?:\/\/[^\s]+)|<a [^>]*href="(https?:\/\/[^\s]+)"[^>]*>(https?:\/\/[^\s]+)<\/a>/g;

  return htmlString.replace(combinedRegex, (match, nakedUrl, href, innerHtml) => {
    const url = nakedUrl || href;
    if (url) {
      const wrapper = document.createElement('div');
      wrapper.innerHTML = `<oembed-element url="${url}"></oembed-element>`;
      return wrapper.innerHTML;
    }
    return match;
  });
}

function processNodesRecursively(node: Node, baseURL: string) {
  if (node instanceof HTMLElement) {
    Array.from(node.childNodes).forEach(child => {
      if (child instanceof HTMLElement && child.tagName === 'OEMBED-ELEMENT') {
        const url = child.getAttribute('url') as string;
        const app = createApp(OembedElement, { url, providerUrl: baseURL });
        app.mount(child);
      } else {
        processNodesRecursively(child, baseURL);
      }
    });
  }
}

export default {
  mounted(el: HTMLElement, binding: DirectiveBinding) {
    const config = useRuntimeConfig()
    const originalHTML = el.innerHTML
    const processedHTML = convertLinksToOembed(originalHTML)
    el.innerHTML = processedHTML

    // Compile the new HTML content to include the Vue component
    const container = document.createElement('div')
    container.innerHTML = processedHTML
    processNodesRecursively(container, config.public.baseURL)
    el.innerHTML = ''
    el.appendChild(container)
  }
};
