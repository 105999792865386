<template>
  <UModal v-model="isOpen">
    <UCard>
      <div class="p-4">
        <img src="/logo.svg" alt="Western Montana News" class="w-56 mx-auto mb-10" />
        <h1 class="text-2xl font-bold text-center">Error {{ props.error.statusCode }}</h1>
        <p class="text-lg text-center">{{ props.error.message }}</p>
      </div>
      <template #footer>
        <div class="flex flex-row-reverse justify-center gap-4">
          <UButton label="Search" @click="clearError({ redirect: '/explore' })" size="xl" icon="i-heroicons-magnifying-glass" />
          <UButton label="Go to Voices" @click="clearError({ redirect: '/voices' })" size="xl" variant="soft" />
        </div>
      </template>
    </UCard>
  </UModal>
</template>

<script setup lang="ts">
import type { NuxtError } from '#app'

const props = defineProps<{
  error: NuxtError
}>()

const isOpen = ref(true)

useHead({
  title: `${props.error.statusCode}`,
  meta: [
    {
      name: 'description',
      content: props.error.statusMessage
    },
    {
      name: 'robots',
      content: 'noindex'
    }
  ]
})
</script>