<template>
  <UCard :ui="cardUi" @click.stop="goToUrl">
    <figure class="flex group/embed">
      <div>
        <img v-if="embed.thumbnail_url" :src="useFeaturedImageUrl(embed.thumbnail_url, 128, 128)" class="w-32 h-full block object-cover">
      </div>
      <figcaption class="p-3 flex-1">
        <h2 class="leading-tight text-sm mb-1">
          <a :href="embed.url || url" v-html="embed.title" class="group-hover/embed:underline line-clamp-2" target="_blank" rel="nofollow"></a>
        </h2>
        <p v-if="embed.author_name && embed.provider_name" class=" text-gray-600 dark:text-gray-500 text-xs mb-1">By {{ embed.author_name }} &bull; {{ embed.provider_name }}</p>
        <p v-else class=" text-gray-600 dark:text-gray-500 text-xs mb-1" v-html="embed.provider_name"></p>
        <p v-if="embed.description" class="text-gray-400 text-xs line-clamp-3">{{ embed.description }}</p>
      </figcaption>
    </figure>
  </UCard>
</template>

<script setup lang="ts">
import type { WP_Embed_Response } from '~/types/interfaces';

const props = defineProps<{
  embed: WP_Embed_Response,
  url: string
}>()

const cardUi = {
  base: 'overflow-hidden max-w-lg mx-auto cursor-pointer',
  body: {
    base: 'dark:bg-gray-700',
    padding: 'p-0 px-0 py-0 sm:p-0',
  }
}

const goToUrl = () => {
  window.open(props.embed.url || props.url, '_blank')
}
</script>