import { default as indexdePh9rNV0qMeta } from "/tmp/build_53a3778f/pages/@[username]/index.vue?macro=true";
import { default as _91slug_93nDMm9D1DpxMeta } from "/tmp/build_53a3778f/pages/@[username]/story/[slug].vue?macro=true";
import { default as _91id_93XT2XaIw1QHMeta } from "/tmp/build_53a3778f/pages/@[username]/voice/[id].vue?macro=true";
import { default as abouto9VJ6VTVVeMeta } from "/tmp/build_53a3778f/pages/about.vue?macro=true";
import { default as advertisetMMyBKmZKlMeta } from "/tmp/build_53a3778f/pages/advertise.vue?macro=true";
import { default as indexkizhA0goiRMeta } from "/tmp/build_53a3778f/pages/compose/index.vue?macro=true";
import { default as index8RJezyU8C2Meta } from "/tmp/build_53a3778f/pages/explore/index.vue?macro=true";
import { default as indexrnjjBEKM7RMeta } from "/tmp/build_53a3778f/pages/firehose/index.vue?macro=true";
import { default as forgot_45passwordNdJUs7P7oRMeta } from "/tmp/build_53a3778f/pages/forgot-password.vue?macro=true";
import { default as index5RKBxKsUnYMeta } from "/tmp/build_53a3778f/pages/index.vue?macro=true";
import { default as indexsnCKisAB4tMeta } from "/tmp/build_53a3778f/pages/listen/index.vue?macro=true";
import { default as indexI6QzX9N0x0Meta } from "/tmp/build_53a3778f/pages/log-in/index.vue?macro=true";
import { default as privacy46QApjmh5bMeta } from "/tmp/build_53a3778f/pages/privacy.vue?macro=true";
import { default as appDr1YRBh0RlMeta } from "/tmp/build_53a3778f/pages/settings/app.vue?macro=true";
import { default as indexUzBuhM7NFaMeta } from "/tmp/build_53a3778f/pages/settings/index.vue?macro=true";
import { default as notificationsWDIwDxrhtFMeta } from "/tmp/build_53a3778f/pages/settings/notifications.vue?macro=true";
import { default as profileUYLkVicTs4Meta } from "/tmp/build_53a3778f/pages/settings/profile.vue?macro=true";
import { default as publishing5MvIubOvAdMeta } from "/tmp/build_53a3778f/pages/settings/publishing.vue?macro=true";
import { default as indexymXrMxsfLLMeta } from "/tmp/build_53a3778f/pages/sign-up/index.vue?macro=true";
import { default as indexERCignUQ7qMeta } from "/tmp/build_53a3778f/pages/stories/index.vue?macro=true";
import { default as termsPVDFkkCz7dMeta } from "/tmp/build_53a3778f/pages/terms.vue?macro=true";
import { default as _91slug_93CjxrtWK5GoMeta } from "/tmp/build_53a3778f/pages/topic/[slug].vue?macro=true";
import { default as indexFS4XDV727tMeta } from "/tmp/build_53a3778f/pages/voices/index.vue?macro=true";
import { default as component_45stubBcRRkv0EXVMeta } from "/tmp/build_53a3778f/node_modules/nuxt/dist/pages/runtime/component-stub.js?macro=true";
import { default as component_45stubBcRRkv0EXV } from "/tmp/build_53a3778f/node_modules/nuxt/dist/pages/runtime/component-stub.js";
export default [
  {
    name: "@username",
    path: "/@:username()",
    component: () => import("/tmp/build_53a3778f/pages/@[username]/index.vue").then(m => m.default || m)
  },
  {
    name: "@username-story-slug",
    path: "/@:username()/story/:slug()",
    component: () => import("/tmp/build_53a3778f/pages/@[username]/story/[slug].vue").then(m => m.default || m)
  },
  {
    name: "@username-voice-id",
    path: "/@:username()/voice/:id()",
    component: () => import("/tmp/build_53a3778f/pages/@[username]/voice/[id].vue").then(m => m.default || m)
  },
  {
    name: "about",
    path: "/about",
    component: () => import("/tmp/build_53a3778f/pages/about.vue").then(m => m.default || m)
  },
  {
    name: "advertise",
    path: "/advertise",
    component: () => import("/tmp/build_53a3778f/pages/advertise.vue").then(m => m.default || m)
  },
  {
    name: "compose",
    path: "/compose",
    component: () => import("/tmp/build_53a3778f/pages/compose/index.vue").then(m => m.default || m)
  },
  {
    name: "explore",
    path: "/explore",
    component: () => import("/tmp/build_53a3778f/pages/explore/index.vue").then(m => m.default || m)
  },
  {
    name: "firehose",
    path: "/firehose",
    component: () => import("/tmp/build_53a3778f/pages/firehose/index.vue").then(m => m.default || m)
  },
  {
    name: "forgot-password",
    path: "/forgot-password",
    component: () => import("/tmp/build_53a3778f/pages/forgot-password.vue").then(m => m.default || m)
  },
  {
    name: "index",
    path: "/",
    component: () => import("/tmp/build_53a3778f/pages/index.vue").then(m => m.default || m)
  },
  {
    name: "listen",
    path: "/listen",
    component: () => import("/tmp/build_53a3778f/pages/listen/index.vue").then(m => m.default || m)
  },
  {
    name: "log-in",
    path: "/log-in",
    meta: indexI6QzX9N0x0Meta || {},
    component: () => import("/tmp/build_53a3778f/pages/log-in/index.vue").then(m => m.default || m)
  },
  {
    name: "privacy",
    path: "/privacy",
    component: () => import("/tmp/build_53a3778f/pages/privacy.vue").then(m => m.default || m)
  },
  {
    name: "settings-app",
    path: "/settings/app",
    component: () => import("/tmp/build_53a3778f/pages/settings/app.vue").then(m => m.default || m)
  },
  {
    name: "settings",
    path: "/settings",
    component: () => import("/tmp/build_53a3778f/pages/settings/index.vue").then(m => m.default || m)
  },
  {
    name: "settings-notifications",
    path: "/settings/notifications",
    meta: notificationsWDIwDxrhtFMeta || {},
    component: () => import("/tmp/build_53a3778f/pages/settings/notifications.vue").then(m => m.default || m)
  },
  {
    name: "settings-profile",
    path: "/settings/profile",
    meta: profileUYLkVicTs4Meta || {},
    component: () => import("/tmp/build_53a3778f/pages/settings/profile.vue").then(m => m.default || m)
  },
  {
    name: "settings-publishing",
    path: "/settings/publishing",
    meta: publishing5MvIubOvAdMeta || {},
    component: () => import("/tmp/build_53a3778f/pages/settings/publishing.vue").then(m => m.default || m)
  },
  {
    name: "sign-up",
    path: "/sign-up",
    meta: indexymXrMxsfLLMeta || {},
    component: () => import("/tmp/build_53a3778f/pages/sign-up/index.vue").then(m => m.default || m)
  },
  {
    name: "stories",
    path: "/stories",
    component: () => import("/tmp/build_53a3778f/pages/stories/index.vue").then(m => m.default || m)
  },
  {
    name: "terms",
    path: "/terms",
    component: () => import("/tmp/build_53a3778f/pages/terms.vue").then(m => m.default || m)
  },
  {
    name: "topic-slug",
    path: "/topic/:slug()",
    component: () => import("/tmp/build_53a3778f/pages/topic/[slug].vue").then(m => m.default || m)
  },
  {
    name: "voices",
    path: "/voices",
    component: () => import("/tmp/build_53a3778f/pages/voices/index.vue").then(m => m.default || m)
  },
  {
    name: component_45stubBcRRkv0EXVMeta?.name,
    path: "/sitemap.xml",
    component: component_45stubBcRRkv0EXV
  }
]