<template>
  <UCard :ui="cardUi" @click.stop="goToUrl">
    <figure class="flex flex-col group/embed">
      <img v-if="embed.thumbnail_url" :src="useFeaturedImageUrl(embed.thumbnail_url, 400)" class="h-52 object-cover">
      <figcaption class="p-4 px-5">
        <h2 class="leading-tight font-serif text-2xl">
          <a :href="url" class="group-hover/embed:underline line-clamp-2" @click.prevent>
            {{ embed.title.replace(' - Western Montana News', '') }}
          </a>
        </h2>
        <p class="text-gray-600 dark:text-gray-500 text-sm mb-2">By {{ embed.author_name }} &bull; {{ embed.provider_name }}</p>
        <p v-if="embed.description" class="text-gray-400 text-sm">{{ embed.description }}</p>
        <UButton @click.stop="goToUrl" label="Read" size="sm" color="gray" class="mt-4" />
      </figcaption>
    </figure>
  </UCard>
</template>

<script setup lang="ts">
import type { WP_Embed_Response } from '~/types/interfaces';

const props = defineProps<{
  embed: WP_Embed_Response,
}>()

const cardUi = {
  base: 'overflow-hidden max-w-lg mx-auto',
  body: {
    base: 'dark:bg-gray-700',
    padding: 'p-0 px-0 py-0 sm:p-0',
  }
}

const url = computed(() => {
  return props.embed.url.replace('https://api.westernmt.news', '')
})

const goToUrl = () => {
  useRouter().push(url.value)
}
</script>