import VueMatomo from 'vue-matomo'
export default defineNuxtPlugin(nuxtApp => {

  const { data: user } = useAuth()

  const config = {
    router: nuxtApp.$router,
    host: 'https://analytics.fervid.digital',
    siteId: 1,
  }

  if (user.value && user.value.slug) {
    config.userId = user.value.slug
  }

  nuxtApp.vueApp.use(VueMatomo, config)

  // Watch user change and login to Matomo when user is logged in
  watch(user, async (newUser) => {
    if (newUser) {
      const { slug } = newUser
      if (!window._paq) return;
      window._paq.push(['setUserId', slug])
    }
  })
})