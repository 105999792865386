import type { RouteLocationNormalized } from "vue-router";

export default defineNuxtRouteMiddleware((to: RouteLocationNormalized, from: RouteLocationNormalized) => {
  const { path } = to;

  // Add this block to redirect /user/ to /@
  if (path.startsWith('/user/')) {
    const newPath = path.replace('/user/', '/@');
    return navigateTo(newPath, { redirectCode: 301 });
  }

  // Add this block to redirect /firehose/drop/ to /@firehose/voice/
  if (path.startsWith('/firehose/drop/')) {
    const newPath = path.replace('/firehose/drop/', '/@firehose/voice/');
    return navigateTo(newPath, { redirectCode: 301 });
  }
})