import type { LVN_API_Post } from '~/types/interfaces'

export default defineNuxtPlugin((nuxtApp) => {

  const { data, open } = useWebSocket(nuxtApp.$config.public.socketUrl, {
    autoReconnect: true,
    heartbeat: true,
  })

  open()

  const bus = useEventBus<number>('post')

  watch(data, (newData) => {
    if (newData) {
      handleMessage(newData)
    }
  })

  const handleMessage = (eventData: any): void => {
    const data = JSON.parse(eventData) as SocketMessage
    useTimeoutFn(() => {
      bus.emit(data.post.id, data)
    }, 200)
  }
})

interface SocketMessage {
  action: 'post_updated' | 'post_deleted' | 'post_created'
  post: LVN_API_Post
}
