import BugsnagPerformance from '@bugsnag/browser-performance'
import { VueRouterRoutingProvider } from '@bugsnag/vue-router-performance'

export default defineNuxtPlugin((nuxtApp) => {
  const router = useRouter()

  BugsnagPerformance.start({
    routingProvider: new VueRouterRoutingProvider(router),
    apiKey: nuxtApp.$config.public.bugsnagApiKey,
    releaseStage: nuxtApp.$config.public.nodeEnv,
    enabledReleaseStages: ['production', 'staging'],
  })
})