<template>
  <OembedTwitter v-if="embed && embed.provider_name === 'Twitter'" :embed="embed" />
  <OembedTruthSocial v-else-if="embed && embed.provider_name === 'truthsocial.com'" :embed="embed" />
  <OembedGenericVideo v-else-if="embed && embed.type === 'video'" :embed="embed" />
  <OembedLVNLink v-else-if="embed && embed.provider_url === 'https://api.westernmt.news'" :embed="embed" />
  <OembedGenericLink v-else-if="embed && !embed.errors" :embed="embed" :url="url" />
  <a v-else-if="embed && embed.errors" :href="url" target="_blank" rel="noopener noreferrer">{{ url }}</a>
  <a v-else :href="url" target="_blank" rel="noopener noreferrer">{{ url }}</a>
</template>

<script setup lang="ts">
import type { WP_Embed_Response } from '~/types/interfaces';

const props = defineProps<{
  url: string,
  providerUrl: string
}>()

const embed = ref<WP_Embed_Response | null>(null)

if (!props.url.includes('facebook.com/')) {
  fetch(props.providerUrl + `/wmn/v1/embed?url=${props.url}`)
  .then(response => response.json())
  .then(data => {
    embed.value = data
  })
  .catch(error => {
    console.error(error)
  })
}
</script>

<style scoped>
a {
  @apply text-primary underline;
}
</style>