import revive_payload_client_4sVQNw7RlN from "/tmp/build_53a3778f/node_modules/nuxt/dist/app/plugins/revive-payload.client.js";
import unhead_KgADcZ0jPj from "/tmp/build_53a3778f/node_modules/nuxt/dist/head/runtime/plugins/unhead.js";
import router_jmwsqit4Rs from "/tmp/build_53a3778f/node_modules/nuxt/dist/pages/runtime/plugins/router.js";
import _0_siteConfig_MwZUzHrRNP from "/tmp/build_53a3778f/node_modules/nuxt-site-config/dist/runtime/nuxt/plugins/0.siteConfig.js";
import payload_client_yVLowv6hDl from "/tmp/build_53a3778f/node_modules/nuxt/dist/app/plugins/payload.client.js";
import navigation_repaint_client_bs2bWDGRIE from "/tmp/build_53a3778f/node_modules/nuxt/dist/app/plugins/navigation-repaint.client.js";
import check_outdated_build_client_8vK7RkfGxZ from "/tmp/build_53a3778f/node_modules/nuxt/dist/app/plugins/check-outdated-build.client.js";
import chunk_reload_client_UciE0i6zes from "/tmp/build_53a3778f/node_modules/nuxt/dist/app/plugins/chunk-reload.client.js";
import components_plugin_KR1HBZs4kY from "/tmp/build_53a3778f/.nuxt/components.plugin.mjs";
import prefetch_client_5tzzN0oIVL from "/tmp/build_53a3778f/node_modules/nuxt/dist/pages/runtime/plugins/prefetch.client.js";
import plugin_w2VlvAEcpa from "/tmp/build_53a3778f/node_modules/nuxt-bugsnag/dist/runtime/client/plugin.mjs";
import pwa_icons_Y6agwlnXZq from "/tmp/build_53a3778f/node_modules/@vite-pwa/nuxt/dist/runtime/plugins/pwa-icons.mjs";
import pwa_client_Eorgdr12jA from "/tmp/build_53a3778f/node_modules/@vite-pwa/nuxt/dist/runtime/plugins/pwa.client.mjs";
import plugin_AOuQ1DYzjk from "/tmp/build_53a3778f/node_modules/@sidebase/nuxt-auth/dist/runtime/plugin.mjs";
import slideovers_X7whXyrIWR from "/tmp/build_53a3778f/node_modules/@nuxt/ui/dist/runtime/plugins/slideovers.js";
import modals_oqljskDzoR from "/tmp/build_53a3778f/node_modules/@nuxt/ui/dist/runtime/plugins/modals.js";
import colors_ETtiVmZNmO from "/tmp/build_53a3778f/node_modules/@nuxt/ui/dist/runtime/plugins/colors.js";
import plugin_client_OVoKJro5pc from "/tmp/build_53a3778f/node_modules/@nuxtjs/color-mode/dist/runtime/plugin.client.js";
import plugin_WLsn00x1qh from "/tmp/build_53a3778f/node_modules/@nuxt/icon/dist/runtime/plugin.js";
import auth_client_4lHQFpoKNF from "/tmp/build_53a3778f/plugins/auth.client.ts";
import bugsnag_perf_client_7qTssx798G from "/tmp/build_53a3778f/plugins/bugsnag-perf.client.ts";
import matomo_client_Ed2EqINkhd from "/tmp/build_53a3778f/plugins/matomo.client.ts";
import oembed_7inBam0z7g from "/tmp/build_53a3778f/plugins/oembed.ts";
import onesignal_client_FpFKSicGgh from "/tmp/build_53a3778f/plugins/onesignal.client.ts";
import socket_client_qBDI6bzE0L from "/tmp/build_53a3778f/plugins/socket.client.ts";
export default [
  revive_payload_client_4sVQNw7RlN,
  unhead_KgADcZ0jPj,
  router_jmwsqit4Rs,
  _0_siteConfig_MwZUzHrRNP,
  payload_client_yVLowv6hDl,
  navigation_repaint_client_bs2bWDGRIE,
  check_outdated_build_client_8vK7RkfGxZ,
  chunk_reload_client_UciE0i6zes,
  components_plugin_KR1HBZs4kY,
  prefetch_client_5tzzN0oIVL,
  plugin_w2VlvAEcpa,
  pwa_icons_Y6agwlnXZq,
  pwa_client_Eorgdr12jA,
  plugin_AOuQ1DYzjk,
  slideovers_X7whXyrIWR,
  modals_oqljskDzoR,
  colors_ETtiVmZNmO,
  plugin_client_OVoKJro5pc,
  plugin_WLsn00x1qh,
  auth_client_4lHQFpoKNF,
  bugsnag_perf_client_7qTssx798G,
  matomo_client_Ed2EqINkhd,
  oembed_7inBam0z7g,
  onesignal_client_FpFKSicGgh,
  socket_client_qBDI6bzE0L
]